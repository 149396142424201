import { gql } from '@apollo/client';
// Fragments
import { StoreDetailsFields } from 'api/store/fragments';
import {
  StreamFields,
  StreamNoteFields,
  GetUpcomingStreamsEntities,
  SoonestStreamFields,
} from './fragments';
import { ThankYouVideoFields } from 'api/thankYouVideo/fragments';

export const MY_STREAMS = gql`
  query MyStreams($input: MyStreamsFilterInput) {
    myStreams(input: $input) {
      total
      offset
      limit
      entities {
        ...StreamFields
        isHidden
      }
    }
  }
  ${StreamFields}
`;

export const MY_SOONEST_STREAM = gql`
  query MySoonestStream($input: MyStreamsFilterInput) {
    myStreams(input: $input) {
      total
      offset
      limit
      entities {
        ...SoonestStreamFields
        isHidden
      }
    }
  }
  ${SoonestStreamFields}
`;

export const MY_OUTCOMING_STREAM_ORDERS = gql`
  query MyOutcomingStreamOrders($input: OutcomingStreamInput) {
    myOutcomingStreamOrders(input: $input) {
      total
      offset
      limit
      entities {
        id
        orderStatus
        paymentMethodId
        price
        discountAmount
        stream {
          isFree
          id
          slug
          name
          description
          isInterview
          imageURL
          streamStatus
          scheduleDate
          repeatsEveryEnum
          streamTips {
            id
            amount
            message
          }
          timeZone {
            tzCode
            offset
          }
          store {
            id
            slug
            lastName
            firstName
            storeDetails {
              ...StoreDetailsFields
            }
          }
          isPurchased
        }
      }
    }
  }
  ${StoreDetailsFields}
`;

export const GET_STREAM = gql`
  query GetStream($input: GetStreamInput!) {
    stream(input: $input) {
      id
      slug
      name
      description
      imageURL
      price
      isFree
      repeatsEveryEnum
      interview {
        id
      }
      streamStatus
      scheduleDate
      type
      isInterview
      hashtags {
        name
        id
      }
      mentions {
        id
        mentionType
        store {
          slug
          id
          storeDetails {
            storeName
            storeId
            avatarURL
          }
          firstName
          lastName
        }
        targetType
        unregisteredContact {
          id
          name
          representativeName
          phoneNumber
          type
          email
          name
          representativeName
          socialMediaLink
          url
        }
      }
      moderator {
        id
        firstName
        lastName
      }
      streamNotes {
        ...StreamNoteFields
      }
      sponsors {
        id
        logoUrl
        name
        promoMessage
      }
      timeZone {
        tzCode
        offset
      }
      isPurchased
      streamRecords {
        id
        streamId
        videoFileKey
        videoURL
        duration
        videoThumbnailURL
      }
      store {
        id
        slug
        lastName
        firstName
        email
        role
        amas {
          id
          type
          price
          videoURL
          videoThumbnailURL
          status
        }
        status
        nextStream {
          id
          slug
          description
          name
          scheduleDate
          timeZone {
            tzCode
            offset
          }
          repeatsEveryEnum
          isPurchased
          streamStatus
          imageURL
          isFree
          price
          store {
            id
            slug
            storeDetails {
              id
              storeName
              storeTitle
              description
              bio
              avatarURL
              coverURL
              socialMediaLink
              gender
              athleticLevel
              careerStatus
            }
          }
        }
        hashtags {
          id
          name
        }
        hasMerch
        hasAma
        hasMediaPost
        hasProducts
        hasMemorabilia
        hasPureProducts
        hasExperiences
        hasStreams
        hasActiveStreams
        hasArticle
        storeDetails {
          storeName
          avatarURL
          description
          bio
        }
      }
    }
  }
  ${StreamNoteFields}
`;

export const GET_STREAM_BY_INVITE = gql`
  query GetStreamByInvite($input: GetStreamByInviteInput!) {
    getStreamByInvite(input: $input) {
      isInterview
    }
  }
`;

export const GET_STREAM_EXTENDED = gql`
  query GetStreamExtended($input: GetStreamInput!) {
    stream(input: $input) {
      id
      slug
      name
      description
      imageURL
      price
      hashtags {
        name
        id
      }
      mentions {
        id
        mentionType
        store {
          id
          slug
          storeDetails {
            storeName
            storeId
            avatarURL
          }
          firstName
          lastName
        }
        targetType
        unregisteredContact {
          id
          name
          representativeName
          phoneNumber
          type
          email
          name
          representativeName
          socialMediaLink
          url
        }
      }
      requestedPrice
      isFree
      repeatsEveryEnum
      repeatingTitle
      isHidden
      streamStatus
      scheduleDate
      isInterview
      scheduleDateTz
      scheduleDateUtc
      moderator {
        id
        firstName
        lastName
      }
      streamNotes {
        ...StreamNoteFields
      }
      sponsors {
        id
        logoUrl
        name
        promoMessage
      }
      timeZone {
        tzCode
        offset
      }
      isPurchased
      streamRecords {
        id
        streamId
        videoFileKey
        videoURL
        duration
        videoThumbnailURL
      }
      store {
        id
        slug
        lastName
        firstName
        email
        role
        amas {
          id
          type
          price
          videoURL
          videoThumbnailURL
          status
        }
        status
        nextStream {
          id
          slug
          description
          name
          scheduleDate
          timeZone {
            tzCode
            offset
          }
          repeatsEveryEnum
          isPurchased
          streamStatus
          imageURL
          isFree
          price
          store {
            id
            slug
            storeDetails {
              id
              storeName
              storeTitle
              description
              bio
              avatarURL
              coverURL
              socialMediaLink
              gender
              athleticLevel
              careerStatus
            }
          }
        }
        hashtags {
          id
          name
        }
        hasMerch
        hasAma
        hasMediaPost
        hasProducts
        hasMemorabilia
        hasPureProducts
        hasExperiences
        hasStreams
        hasActiveStreams
        hasArticle
        storeDetails {
          storeName
          avatarURL
          description
          bio
        }
      }
    }
  }
  ${StreamNoteFields}
`;

export const GET_STREAMS = gql`
  query GetStreams(
    $input: StreamFilterInput!
    $withFilters: Boolean = false
    $withSponsors: Boolean = false
  ) {
    streamsV2(input: $input) {
      entities {
        ...GetUpcomingStreamsEntities
        sponsors @include(if: $withSponsors) {
          id
          logoUrl
          name
          promoMessage
        }
      }
      total
      offset
      limit
      filtersV2 @include(if: $withFilters) {
        scheduleDate
        price
        streamStatusV2
      }
    }
  }
  ${GetUpcomingStreamsEntities}
`;

export const SEARCH_STREAMS = gql`
  query SearchStreams($input: StreamFilterInput!) {
    streamsV2(input: $input) {
      entities {
        id
        name
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
  }
`;

export const GET_ATHLETE_STREAMS = gql`
  query GetAthleteStreams(
    $liveAndUpcomingStreamsInput: StreamFilterInput!
    $pastStreamsInput: StreamFilterInput!
    $withFilters: Boolean = false
  ) {
    liveAndUpcomingStreams: streamsV2(input: $liveAndUpcomingStreamsInput) {
      entities {
        ...GetUpcomingStreamsEntities
      }
      total
      offset
      limit
      filtersV2 @include(if: $withFilters) {
        scheduleDate
        price
        streamStatusV2
      }
    }
    pastStreams: streamsV2(input: $pastStreamsInput) {
      entities {
        ...GetUpcomingStreamsEntities
      }
      total
      offset
      limit
      filtersV2 @include(if: $withFilters) {
        scheduleDate
        price
        streamStatusV2
      }
    }
  }
  ${GetUpcomingStreamsEntities}
`;

export const GET_MY_STREAM_SALES = gql`
  query GetStreamSales($input: StreamSalesInput) {
    getStreamSales(input: $input) {
      total
      offset
      limit
      entities {
        stream {
          id
          name
          streamStatus
          scheduleDate
          repeatsEveryEnum
        }
        thankYouVideo {
          ...ThankYouVideoFields
        }
        requestedProfit
        orderAmount
        tipAmount
      }
    }
  }
  ${ThankYouVideoFields}
`;

export const GET_MY_STREAM_ORDER_SALES = gql`
  query GetStreamOrderSales(
    $input: StreamOrderAndTipsTypeInput
    $streamId: String!
  ) {
    getStreamOrderSales(input: $input, streamId: $streamId) {
      total
      offset
      limit
      entities {
        id
        firstName
        lastName
        paidat
        earnings
        discount
        type
      }
    }
  }
`;

export const GET_REGISTERED_STREAM_USERS = gql`
  query GetRegisteredStreamUsers($input: GetStreamUsersInput!) {
    getRegisteredStreamUsers(input: $input) {
      total
      offset
      limit
      entities {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_AFFILIATE_STREAM_PRODUCTS = gql`
  query GetAffiliateStreamProducts($input: GetAffiliateStreamProductsInput!) {
    getAffiliateStreamProducts(input: $input) {
      id
      imageKey
      imageUrl
      orderIndex
      price
      title
      url
    }
  }
`;

export const GET_STORE_STREAMS = gql`
  query GetStoreStreams($input: GetStoreStreamsInput!) {
    getStoreStreams(input: $input) {
      entities {
        ...GetUpcomingStreamsEntities
        isHidden
      }
      total
      offset
      limit
    }
  }
  ${GetUpcomingStreamsEntities}
`;

export const GET_STREAM_PARTICIPANTS = gql`
  query GetStreamParticipants($input: StreamParticipantFilterInput!) {
    getStreamParticipants(input: $input) {
      entities {
        id
        firstName
        lastName
        slug
        storeDetails {
          storeName
          avatarURL
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_OR_CREATE_STREAM_PARTICIPANT_INVITE = gql`
  query GetOrCreateStreamParticipantInvite(
    $input: StreamParticipantInviteInput!
  ) {
    getOrCreateStreamParticipantInvite(input: $input) {
      id
      invite
    }
  }
`;
