import { gql } from '@apollo/client';

import { StreamFields, StreamNoteFields } from 'api/streams/fragments';

export const CREATE_STREAM = gql`
  mutation CreateStream($input: StreamCreateInput!) {
    createStream(input: $input) {
      ...StreamFields
      isHidden
    }
  }
  ${StreamFields}
`;

export const UPDATE_STREAM = gql`
  mutation UpdateStream($input: StreamUpdateInput!) {
    updateStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const CANCEL_STREAM = gql`
  mutation CancelStream($id: String!) {
    cancelStream(id: $id) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const CREATE_STREAM_ORDER = gql`
  mutation CreateStreamOrder($input: StreamOrderCreateInput!) {
    createStreamOrder(input: $input) {
      id
      stripeIntentClientSecret
      stripeIntentID
      stream {
        id
        streamStatus
        isPurchased
      }
    }
  }
`;

export const END_STREAM = gql`
  mutation EndStream($id: String!) {
    endStream(id: $id) {
      id
      streamStatus
    }
  }
`;

export const COMPLETE_STRIPE_PAYMENT = gql`
  mutation CompleteStripePayment($input: CompleteStripePaymentInput!) {
    completeStripePayment(input: $input) {
      status
    }
  }
`;

export const SEND_REQUEST_HELP_EMAIL = gql`
  mutation SendRequestHelpEmail($input: RequestHelpInputEmail!) {
    sendRequestHelpEmail(input: $input)
  }
`;

export const START_STREAM = gql`
  mutation StartStream($input: StartStreamInput!) {
    startStream(input: $input) {
      id
      streamStatus
    }
  }
`;

export const PAUSE_STREAM = gql`
  mutation PauseStream($id: String!) {
    pauseStream(id: $id) {
      id
      streamStatus
    }
  }
`;

export const CREATE_STREAM_NOTE = gql`
  mutation CreateStreamNote($input: StreamNoteCreateInput!) {
    createStreamNote(input: $input) {
      ...StreamNoteFields
    }
  }
  ${StreamNoteFields}
`;

export const HIDE_STREAM = gql`
  mutation HideStream($input: HideStreamInput!) {
    hideStream(input: $input) {
      id
      isHidden
    }
  }
`;

export const UNHIDE_STREAM = gql`
  mutation UnhideStream($input: UnhideStreamInput!) {
    unhideStream(input: $input) {
      id
      isHidden
    }
  }
`;

export const ADD_PARTICIPANT_TO_STREAM = gql`
  mutation AddParticipantToStream($input: AddParticipantsToStreamInput!) {
    addParticipantToStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const SET_INTERVIEW_FEEDBACK = gql`
  mutation SetInterviewFeedback($input: SetInterviewFeedbackInput!) {
    setInterviewFeedback(input: $input) {
      id
      feedback
    }
  }
`;
